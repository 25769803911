<template>
  <div class="container">
    <perseu-card title="Ordenar oligoelementos por tipo">
      <template #content>
        <div>
          <h4 class="subtitle-2">Tipo selecionado: {{ items[0].type.name }}</h4>
        </div>
        <v-list class="mt-5 body-2">
          <draggable :list="items">
            <template v-for="(oligoelement, index) in items">
              <v-list-item :key="oligoelement.id" class="elevation-2 mb-2">
                <v-list-item-action>
                  <v-icon>reorder</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  {{ index + 1 }} - {{ oligoelement.name }}
                </v-list-item-content>
              </v-list-item>
            </template>
          </draggable>
        </v-list>
      </template>
      <template #actions>
        <v-btn outlined @click="backToList">Voltar</v-btn>
        <v-btn color="secondary" @click="save">Salvar</v-btn>
      </template>
    </perseu-card>
  </div>
</template>

<script>
import { changeOrder, getByType } from "@/services/oligoelements-service";
import draggable from "vuedraggable";
import { pagesNames } from "@/router/pages-names";

export default {
  name: "Order",
  components: {
    draggable,
  },
  data: () => ({
    items: [],
  }),
  created() {
    this.populateList();
  },
  methods: {
    async populateList() {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await getByType(this.$route.params.typeId);
        this.items = data;
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async save() {
      try {
        this.$store.dispatch("loading/openDialog");
        const formattedList = this.items.map((oligoelement, index) => ({
          id: oligoelement.id,
          order: index + 1,
        }));
        await changeOrder(formattedList);
        this.$router.push({ name: pagesNames.OLIGOELEMENTS_LIST });
        this.backToList();
      } catch (e) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    backToList() {
      this.$router.push({ name: pagesNames.OLIGOELEMENTS_LIST });
    },
  },
};
</script>

<style scoped></style>
