import api from "./api-service";
import { stringify } from "querystring";

const baseUrl = "oligoelements";

export const getAll = (filter) => api().get(`${baseUrl}?${stringify(filter)}`);

export const getByType = (typeId) => api().get(`${baseUrl}/type/${typeId}`);

export const getOne = (id) => api().get(`${baseUrl}/${id}`);

export const create = (oligoelement) => api().post(baseUrl, oligoelement);

export const update = (oligoelement) => api().put(baseUrl, oligoelement);

export const saveType = (type) => api().post(baseUrl + "/type", type);

export const updateType = (type) => api().put(baseUrl + "/type", type);

export const findTypes = (filters) =>
  api().get(`${baseUrl}/types?${stringify(filters)}`);

export const changeOrder = (oligoelements) => {
  return api().put(`${baseUrl}/order`, oligoelements);
};
